(function($) {
    const menu = $('.js-navbar > .hb__list');
    const secNav = $('.js-hb-addtomenu');

    const mql = window.matchMedia('(min-width: 1025px)');
    mql.addListener(onMediaQueryChanged);

    /** @param {MediaQueryList} event */
    function onMediaQueryChanged(event) {
        showMenu(event.matches);
    }

    function showMenu(desktop) {
        if (desktop) {
            //hamburgermenu is standaard onzichtbaar (attr hidden).
            menu.attr('hidden', false);

            secNav.closest('.navbar').siblings('.js-navbar').find('.js-hb-addtomenu').remove();
            secNav.show();
        } else {
            //navbar moet standaard wél zichtbaar zijn, maar weer hidden als het de hamburger-variant wordt op mobiel.
            menu.attr('hidden', true);
            menu.find('.js-hb-addtomenu').attr('hidden', true);

            // secundair menu
            const clonedSecMenu = secNav.clone();
            secNav.hide();

            secNav.closest('.navbar').siblings('.js-navbar').append(clonedSecMenu);
        }
    }
    showMenu(mql.matches);


    if (typeof(IntersectionObserver) === "function") {
        let observer = new IntersectionObserver(onIntersect);

        $('.has-submenu .has-submenu').on('mouseenter', function() {
            const el = $(this).find('.hb__list');

            setTimeout(function() {
                el && observer.observe(el[0]);
            }, 200); //transition duration
        });

        function onIntersect(entries, observer) {
            for (let index in entries) {
                let entry = entries[index];

                if (entry.intersectionRatio < 1 && entry.intersectionRatio > 0) {
                    $(entry.target).addClass('hb__list--flip');
                }
            }
        }
    }

    //keyboard support
    $('.js-navbar .hb__link').keydown(function(e) {
        const arrowKeys = [37,38,39,40];

        // only do something on arrow keys
        if (arrowKeys.indexOf(e.keyCode) === -1) return;

        const item = $(this);
        const isSubitem = $(this).parent().parents('.has-submenu').length !== 0;
        const hasSubitems = $(this).parent().hasClass('has-submenu');

        const openText = $(this).closest('[data-hbnav-open]').data('hbnav-open') || "Open";
        const closeText = $(this).closest('[data-hbnav-close]').data('hbnav-close') || "Close";

        let previousItem, nextItem;

        switch(e.keyCode) {
            case 37: // left
                if (isSubitem) {
                    previousItem = item.parent().parents('.has-submenu').first();
                    previousItem.find('.hb__list').attr('hidden', true);

                    previousItem.find('.js-hb-toggle')
                        .attr('aria-label', openText)
                        .attr('aria-expanded', false);

                    previousItem.find('.hb__link').focus();
                } else {
                    previousItem = item.parent().prev();
                    previousItem.find('.hb__link').first().focus();
                }
                break;
            case 38: // up
                if (isSubitem) {
                    previousItem = item.parent().prev();
                    previousItem.find('.hb__link').first().focus();

                    if (previousItem.length === 0) {
                        previousItem = $(this).parent().parents('.has-submenu');

                        if (previousItem.length === 1) {
                            previousItem.find('.hb__list').attr('hidden', true);

                            previousItem.find('.js-hb-toggle')
                                .attr('aria-label', openText)
                                .attr('aria-expanded', false);

                            previousItem.find('.hb__link').focus();
                        }
                    }
                } else {
                    previousItem = item.siblings('.hb__list');
                    previousItem.attr('hidden', true);
                    previousItem.siblings('.js-hb-toggle').attr('aria-label', openText);
                }
                break;
            case 39: // right
                if (isSubitem) {
                    if (hasSubitems) {
                        nextItem = item.siblings('.hb__list');
                        nextItem.attr('hidden', false);

                        item.siblings('.js-hb-toggle')
                            .attr('aria-label', closeText)
                            .attr('aria-expanded', true);

                        nextItem.find('.hb__link').first().focus();
                    }
                } else {
                    nextItem = item.parent().next();
                    nextItem.find('.hb__link').first().focus();
                }
                break;
            case 40: // down
                if (isSubitem) {
                    nextItem = item.parent().next();
                    nextItem.find('.hb__link').first().focus();
                } else {
                    if (hasSubitems) {
                        nextItem = item.siblings('.hb__list');
                        nextItem.attr('hidden', false);

                        item.siblings('.js-hb-toggle')
                            .attr('aria-label', closeText)
                            .attr('aria-expanded', true);

                        nextItem.find('.hb__link').first().focus();
                    }
                }
                break;
        }

        return false;
    });

    /**
    * Add class to header on scroll
    */
    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 0) {
            $(".nav__header").addClass("nav__header--shrunk");
        } else {
            $(".nav__header").removeClass("nav__header--shrunk");
        }
        if ($(".nav__header").hasClass("nav__header--shrunk")) {
            $("main").addClass("main--shrunk");
        } else {
            $("main").removeClass("main--shrunk");
        }
    });


})(jQuery);
