// toggle = open next menu. Set class and aria values.
// sued by hamburger button and buttons for submenus.

(function ($) {
    $(document).on('click', '.js-hb-toggle', function () {
        if (!window.matchMedia('(pointer: coarse)').matches) {
            $(this).parent().siblings().find('.js-hb-toggle.is-open').click();
        }

        this.classList.toggle('is-open');

        let expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', !expanded);

        const openText = $(this).closest('[data-hbnav-open]').data('hbnav-open') || "Open";
        const closeText = $(this).closest('[data-hbnav-close]').data('hbnav-close') || "Close";
        this.setAttribute('aria-label', !expanded ? closeText : openText);

        if ($(this).hasClass('hb__toggle')) {
            const secNav = $(this).closest('.js-navbar').find('.js-hb-addtomenu');
            if (secNav.length) {
                secNav[0].hidden = !secNav[0].hidden;
            }
        }

        let submenu = $(this).next().hasClass('hb__list') ? $(this).next()[0] : $(this).next().find('.hb__list')[0];
        submenu.hidden = !submenu.hidden;
    });

    //if li has submenu, add class has-submenu
    $(".hb__list > li").has("ul").addClass('has-submenu');

    if (window.matchMedia('(min-width: 1024px)').matches) {
        // hide secondary navigation on mobile devices
        $('.js-hb-addtomenu').attr('hidden', false);
    } else { 
        $('.js-hb-addtomenu').attr('hidden', true);
    }

    // hier ook een keer naar kijken https://foxland.fi/accessible-multi-level-dropdown-navigation/
    // http://samikeijonen.github.io/responsive-nav.js/demos/multiple-levels/index.html
})(jQuery);
