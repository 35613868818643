$('.nav-tabs li a').click(function (e) {
    //get selected href
    var href = $(this).attr('href');
    // show tab for all tabs that match href
    $('.nav-tabs li a[href="' + href + '"]').tab('show');
    // Fix position slick slider on active tab.
    $('a[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
        if ($.fn.slick) {

            $('.slider-container').slick('setPosition');
        }
    });

})
if ($.fn.slick) {

    $('.slider-container').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
}
/* if page contains change href in mobile cta */
$(function () {
    if ($('#contactform').length) {
        $("a.mobile-contact").attr("href", "#contactform");
    } else {

    }
});
