



$(window).on("scroll", function () {
    if ($(this).scrollTop() > 200) {
        $(".scroll-to-top").addClass("scroll-to-top--visible");
    } else {
        $(".scroll-to-top").removeClass("scroll-to-top--visible");
    }

    // Check if the element is visible
    if ($(".scroll-to-top").is(":visible")) {
        let footerOffet = $(".footer").offset().top - $(window).height();
        // Add scroll to top class when footer is visible
        if ($(this).scrollTop() >= footerOffet) {
            $(".scroll-to-top").addClass("scroll-to-top--offset");
        } else {
            $(".scroll-to-top").removeClass("scroll-to-top--offset");
        }
    }
});

// Scroll to top
$(".scroll-to-top").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, 400);
});
