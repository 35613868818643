//Add class to scroll to top on scroll

$(window).on("scroll", function () {
    if ($(this).scrollTop() > 200) {
        $(".scroll-to-top").addClass("scroll-to-top--visible");
    } else {
        $(".scroll-to-top").removeClass("scroll-to-top--visible");
    }

    // Check if the element is visible
    if ($(".scroll-to-top").is(":visible")) {
        let footerOffet = $(".footer").offset().top - $(window).height();
        // Add scroll to top class when footer is visible
        if ($(this).scrollTop() >= footerOffet) {
            $(".scroll-to-top").addClass("scroll-to-top--offset");
        } else {
            $(".scroll-to-top").removeClass("scroll-to-top--offset");
        }
    }
});

// Scroll to top
$(".scroll-to-top").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, 400);
});

// Scroll to form on landingpage products
if (window.matchMedia('(max-width: 768px)').matches)
{
    $(function() {
        $(".scroll-to-form").on('click', function() {
            $("html, body").animate({
                scrollTop: 2000
            }, 1000);
        });
    });
}

if (window.matchMedia('(min-width: 768px)').matches)
{
    $(function() {
        $(".scroll-to-form").on('click', function() {
            $("html, body").animate({
                scrollTop: 800
            }, 1000);
        });
    });
}

$('a[href*="#"], .scrollto')
// script to remove links that don't actually link to anything and to add a nice scroll function on links to an ancor
.not('[href="#"]')

.click(function (event) {
    // On-page links
    if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
    ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - 200
            }, 2000, function () {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                } else {
                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                };
            });
        }
    }
});
