// $(document).ready(function () {
//     var formFields = $(".elq-field-style");

//     formFields.each(function () {
//         var field = $(this);
//         var input = field.find("input[type=text], textarea");
//         var label = field.find("label");

//         function checkInput() {
//             var valueLength = input.val().length;

//             if (valueLength > 0) {
//                 label.addClass("active");
//             } else {
//                 label.removeClass("active");
//             }
//         }

//         input.change(function () {
//             checkInput();
//         });

//         input.on("focusin", function () {
//             label.addClass("active");
//         });

//         input.on("focusout", function () {
//             if (valueLength > 0) {
//             label.removeClass("active");
//             }
//         });
//     });
// });
