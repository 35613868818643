$(function () {
    if ($('#reference-slider .slider__item').length > 1) { 
      
        // only if more than 1 item
        $('#reference-slider .js-slider').owlCarousel({
            items: 5,
            loop: false,
            margin: 30,

            slideBy: 'page',

            dots: true,
            dotsContainer: '.owl-thumbnail-dots',

            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    
                },
                768: {
                    items: 5,
                    nav: false,
                }
            }
        });
    }
});
