// check if the landingspage has bool -moveFAQ- then move the faq to the left of the form

$(document).ready(function() {
    if ($('div').hasClass('move-faq')) {
        $('.faq').insertAfter('.list--email');
    } else {
    }
});

// hide the contact buttons in the header

$(document).ready(function() {
    if ($('div').hasClass('hideContact')) {
        $('header .button').css('display', 'none');
    } else {
        $('header .button').css('opacity', '1');
    }
});
