let skiplinks = document.querySelectorAll('.js-skiplinks');

for (let i = 0; i < skiplinks.length; i++) {
    let el = skiplinks[i];
    el.addEventListener('focus', function() {
        el.classList.remove('visually-hidden');
    });
    el.addEventListener('blur', function() {
        el.classList.add('visually-hidden');
    });
}
