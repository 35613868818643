$(function () {
    $(".field .field__input")
        .on("focusin", function (e) {
            // gain focus
            $(this).closest(".field").addClass("focussed");
        })
        .on("focusout", function (e) {
            // lose focus
            $(this).closest(".field").removeClass("focussed");
        })
        .on("keyup change paste", function (e) {
            var value = $(e.target).val();
            if (value !== undefined && value !== null && value.length > 0) {
                $(this)
                    .closest(".field")
                    .removeClass("pristine")
                    .addClass("dirty");
            } else {
                $(this)
                    .closest(".field")
                    .removeClass("dirty")
                    .addClass("pristine");
            }
        });

    $(".field").addClass("pristine");
});

//fix input bij reload of autofill
$(window).on("load", function () {
    $(".field .field__input").each(function () {
        var value = $(this).val();
        if (
            (value !== undefined && value !== null && value.length > 0) ||
            $(this).is("*:-webkit-autofill")
        ) {
            $(this).closest(".field").addClass("dirty");
        }
    });
});

