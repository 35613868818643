(function(){
    if (typeof(datepicker) === "function") {
        const picker = datepicker(document.querySelector('.js-datepicker'), {
            position: 'bl', // bl for Bottom left, tl for top left, etc.
            startDate: new Date(), // This month.
            startDay: 1, // Calendar week starts on a Monday.
            dateSelected: new Date(), // Today is selected.
            disabledDates: [new Date('1/1/2050'), new Date('1/3/2050')], // Disabled dates.
            minDate: new Date(2016, 5, 1), // June 1st, 2016.
            maxDate: new Date(2099, 0, 1), // Jan 1st, 2099.
            noWeekends: false, // Weekends will be selectable.

            formatter: function (el, date) {
                // This will display the date as `1/1/2017`.
                //el.value = date.toDateString();
                el.value = date.toLocaleDateString("nl", { year: "numeric", month: "long", day: "2-digit" })
            },
            onSelect: function (instance) {
                // Show which date was selected.
                console.log(instance.dateSelected);
            },
            onShow: function (instance) {
                //console.log('Calendar showing.');
            },
            onHide: function (instance) {
                //console.log('Calendar hidden.');
            },
            onMonthChange: function (instance) {
                // Show the month of the selected date.
                //console.log(instance.currentMonthName);
            },
            //vertaling naar NL:
            customMonths: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
            customDays: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
            overlayPlaceholder: 'Verander het jaar (4 cijfers)', //EN:Enter a 4-digit year
            overlayButton: 'Jaar instellen' //EN: go!

        });
        //gebruikt library datepicker.js en css datepicker.css
    }
})();
