$(function () {
    if ($('#product-slider-single .slider__item').length >= 1) {
        // only if more than 1 item

        $('#product-slider-single.js-slider').owlCarousel({
            items: 1,
            loop: false,
            margin: 10,

            dots: false,
            nav: false,

            responsiveClass: true,

            autoplay: false,
            autoplayTimeout: 1500,
            autoplayHoverPause: true,

            lazyLoad: true,
            thumbs: true,
            thumbImage: false,
            thumbsPrerendered: true,
            thumbItemClass: 'slider__item',
        });
    }

    if ($('#product-slider-thumbs .slider__item').length >= 1) {
        // only if more than 1 item

        $('#product-slider-thumbs.js-slider').owlCarousel({
            items: 3,
            loop: false,
            margin: 10,

            slideBy: 'page',

            dots: true,
            dotsContainer: '.owl-thumbnail-dots',
            nav: false, 
            

            responsiveClass: true,
            
            autoplay: false,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,

            lazyLoad: true,

        });
    }
});
