//Search component
(function ($) {
    $(function () {
        let $searchInput = $(".js-search-expand .search__input");
        $searchInput.attr('tabindex', '-1');

        $('.js-search-expand .button').click(function (e) {
            e.preventDefault();

            const self = this;
            const search = $(self).siblings('input[type="search"]');

            setTimeout(function () {
                if ($(search).val() !== '' && $(search).hasClass('search__input--expanding--visible')) {
                    //$(self.form).submit(); // submit form
                } else if (!$(search).hasClass('search__input--expanding--visible')) {
                    $(search).addClass('search__input--expanding--visible');
                    $(search).attr('tabindex', '');
                }

                $(search).focus();
            }, 10);
        });

        $searchInput.on('focusout', function (e) {
            const self = $(this);

            setTimeout(function () {
                if (!$(document.activeElement).is('.js-search-expand .search__icon-button--expanding')) {
                    self.removeClass('search__input--expanding--visible');
                    self.attr('tabindex', '-1');
                }
            }, 10);
        });
    });
})(jQuery);
