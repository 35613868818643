$(function() {
    if ($('#1518616682 .slider__item').length > 1) {
        // only if more than 1 item

        $('#1518616682 .js-slider').owlCarousel({
            items: 1,
            loop: true,
            margin: 10,

            dots: true,
            nav: true,
            navText: [
                '<i class="far fa-angle-left"></i>',
                '<i class="far fa-angle-right"></i>'
            ],

            responsiveClass: true,

            autoplay: true,
            autoplayTimeout: 1500,
            autoplayHoverPause: true,
        });
    }
});
